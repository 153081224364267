import React, { useState } from "react";
import {
    Autocomplete,
    Button,
    OutlinedInput,
    TextField,
    SvgIcon,
    InputAdornment,
} from "@mui/material";
import { useRiders } from "../../hooks/use-users";

import MagnifyingGlassIcon from "@mui/icons-material/Search";
import { CourierType } from "../../types";

interface AssignDeliveryProps {
    onAssign: (riderId: number) => void;
    courierType: CourierType;
}

const AssignDelivery: React.FC<AssignDeliveryProps> = ({ onAssign, courierType }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRider, setSelectedRider] = useState<number | null>(null);
    const { data: riders, isLoading } = useRiders(searchQuery, courierType);

    const handleAssign = () => {
        if (selectedRider !== null) {
            onAssign(selectedRider);
        }
    };

    return (
        <div>
            <OutlinedInput
                onChange={(e) => setSearchQuery(e.target.value)}
                defaultValue=""
                fullWidth
                placeholder="Search by name, email or phone"
                startAdornment={
                    <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                            <MagnifyingGlassIcon />
                        </SvgIcon>
                    </InputAdornment>
                }
                sx={{ maxWidth: 500, marginBottom: 2 }}
            />
            <Autocomplete
                options={riders ?? []}
                getOptionLabel={(rider) => `${rider.user.first_name} ${rider.user.last_name}`}
                loading={isLoading}
                renderInput={(params) => <TextField {...params} label="Select Rider" />}
                onChange={(event, rider) => setSelectedRider(rider?.id || null)}
                sx={{ marginBottom: 2 }}
            />
            <Button variant="contained" onClick={handleAssign}>
                Assign Delivery
            </Button>
        </div>
    );
};

export default AssignDelivery;
