import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../../../context/auth-context";

export const AuthGuard: React.FC<React.PropsWithChildren> = ({ children }) => {
  const history = useNavigate();
  const location = useLocation();
  const { isAuthenticated, logout } = useAuthContext();

  useEffect(() => {
    if (!isAuthenticated) {
      console.log("Not authenticated, redirecting");
      history({
        pathname: "/auth/login",
      });
    }
  }, [isAuthenticated, location.pathname]);

  const handleUnauthorized = (event: any) => {
    if (!location.pathname.includes("auth")) {
      logout()
    }

  }
  useEffect(() => {
    window.addEventListener('unauthorized', handleUnauthorized);
    return () => {
      window.removeEventListener('unauthorized', handleUnauthorized);
    };
  }, []);

  return <>{children}</>;
};
