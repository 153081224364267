import { Button, Card, Checkbox, List, ListItem, ListItemText, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { useToast } from '../hooks/use-toast';
import client from '../api';
import { useParams } from 'react-router-dom';
import { useRider, useUpdateRiderOrAddVerificationDocument } from '../hooks/use-users';
import React from 'react';
import { Rider } from '../types';

export default function RiderDetailsPage() {
    const { id } = useParams();
    const { data: rider, isLoading: loading, error } = useRider(id as any);
    const { showToast } = useToast();
    const { mutate } = useUpdateRiderOrAddVerificationDocument();


    const downloadAll = async (event: any, path: any) => {
        showToast({ message: "Downloading please wait..", type: "success" });
        await client.downloadAll(path).catch(error => {
            showToast({ message: "Downloading failed. Try again later", type: "error" });
        });
    };
    const verify = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        mutate({
            id,
            data: {
                verified: e.target.checked,
            },
        });
    };

    React.useEffect(() => {
        if (error) {
            showToast({
                message: error.message,
                type: "error",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);


    return (
        <div>
            {loading && <div>Loading...</div>}
            {rider &&
                <div>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>User Details</Typography>
                            <List>
                                <ListItem >
                                    <ListItemText primary="First Name" secondary={rider.user.first_name} />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Last Name" secondary={rider.user.last_name} />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Email" secondary={rider.user.email} />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Phone Number" secondary={rider.user.phone_number} />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Phone Number 1" secondary={rider.user.phone_number_1 || "N/A"} />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Created At" secondary={rider.user.created_at} />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Profile Image" secondary={
                                        <RenderAction rider={rider} document_name='profile_image' />
                                    } />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Phone Verified" secondary={rider.user.phone_verified ? "Yes" : "No"} />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Email Verified" secondary={rider.user.email_verified ? "Yes" : "No"} />
                                </ListItem>

                                <ListItem >
                                    <ListItemText primary="Is Admin" secondary={rider.user.is_admin ? "Yes" : "No"} />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Last Active" secondary={rider.user.last_active || "N/A"} />
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                    <br />
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Rider Details</Typography>
                            <List>
                                <ListItem >
                                    <ListItemText primary="Verified" secondary={rider.verified ? "Yes" : "No"} />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Rider ID Image" secondary={

                                        <RenderAction rider={rider} document_name='rider_id_image' />
                                    }
                                    />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="KRA Image" secondary={

                                        <RenderAction rider={rider} document_name='kra_image' />
                                    } />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Good Conduct Certificate Image" secondary={

                                        <RenderAction rider={rider} document_name='good_conduct_certificate_image' />
                                    } />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Courier Type" secondary={rider.courier_type} />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Courier Owner ID Image" secondary={

                                        <RenderAction rider={rider} document_name='courier_owner_id_image' />
                                    } />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Logbook Image" secondary={
                                        <RenderAction rider={rider} document_name='logbook_image' />

                                    } />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Driver License Image" secondary={
                                        <RenderAction rider={rider} document_name='driver_license_image' />

                                    } />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Motorcycle Image" secondary={
                                        <RenderAction rider={rider} document_name='motorcycle_image' />

                                    } />
                                </ListItem>

                                <ListItem >
                                    <ListItemText primary={"Verified " + rider.verified ? "Yes" : "No"} secondary={
                                        <span> Verify
                                            <Checkbox

                                                checked={rider.verified}
                                                onChange={(e) => verify(e, rider.id)}
                                            />
                                        </span>
                                    } />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="Download Verification Documents" secondary={
                                        <Button
                                            onClick={(e) => downloadAll(e, rider.good_conduct_certificate_image.split("/").slice(0, -1).join("/"))}
                                            style={{ cursor: "pointer" }}
                                        >
                                            Download All
                                        </Button>
                                    } />
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                </div>
            }
        </div >
    );
}


function RenderAction({ rider, document_name }: { rider: Rider, document_name: string }) {
    const { showToast } = useToast();
    const { mutate } = useUpdateRiderOrAddVerificationDocument();

    const handleImageClick = async (event: any, path: any) => {
        showToast({ message: "Downloading please wait..", type: "success" });
        await client.download(path).catch(error => {
            showToast({ message: "Downloading failed. Try again later", type: "error" });
        });
    };

    //@ts-ignore
    const url = rider[document_name] ?? rider.user[document_name]
    return (
        <div style={{ display: "flex", margin: "10px 10px 0 0" }}>
            <div style={{ margin: "0 10px 0 0", width: "200px" }}>

                {rider.requested_documents.map(doc => doc.name).includes(document_name) ? (
                    <strong>Requested</strong>
                ) : (
                    <Button
                        onClick={() => mutate({ id: rider.id, data: document_name })}
                        variant="outlined"
                        color="secondary"
                    >
                        Request Re-upload
                    </Button>
                )}
            </div>
            <div >
                {url ? (
                    <Button
                        onClick={(e) => handleImageClick(e, url)}
                        style={{ cursor: "pointer" }}
                        variant="contained"
                        color="primary"
                    >
                        Download
                    </Button>
                ) : (
                    "N/A"
                )}
            </div>
        </div>
    );
}