import { useCallback, useState } from "react";
import {
  Box,
  Card,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import MagnifyingGlassIcon from "@mui/icons-material/Search";
import { RidersTable } from "../sections/user/riders-table";
import { CourierType } from "../types";

const Riders = () => {
  const [searchQuery, setSearch] = useState<string | undefined>(undefined);
  const [filter, setFilter] = useState<any>("All");

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );
  const handleFilterChange = (event: any) => {
    setFilter(event.target.value as any);
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Riders</Typography>
              </Stack>
            </Stack>
            <Card sx={{ p: 2 }}>
              <Stack direction="row" justifyContent="space-between" spacing={4} width="100%">
                <OutlinedInput
                  onChange={handleSearch}
                  defaultValue=""
                  fullWidth
                  placeholder="Search by name,	email or	phone"
                  startAdornment={
                    <InputAdornment position="start">
                      <SvgIcon color="action" fontSize="small">
                        <MagnifyingGlassIcon />
                      </SvgIcon>
                    </InputAdornment>
                  }
                  sx={{ maxWidth: 500 }}
                />
                <FormControl sx={{
                  minWidth: "150px"
                }}>
                  <InputLabel id="filter-label">Courier Type</InputLabel>
                  <Select
                    labelId="filter-label"
                    id="filter"
                    value={filter || ""}
                    onChange={handleFilterChange}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {Object.values(CourierType).map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Card>
            <RidersTable searchQuery={searchQuery} filter={filter === "All" ? undefined : filter} />
          </Stack>
        </Container>
      </Box >
    </>
  );
};

export default Riders;
