import { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { TopNav } from "./top-navbar";
import { SideNav } from "./sidebar";
import { Outlet, useLocation } from "react-router-dom";
import { AuthGuard } from "./auth-guard";

const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const LayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export const DashboardLayout: React.FC = () => {
  const pathname = useLocation();
  const [openNav, setOpenNav] = useState(false);


  useEffect(() => {
    setOpenNav(false);
  }, [pathname]);

  return (
    <AuthGuard>
      <TopNav onNavOpen={() => setOpenNav(true)} />
      <SideNav onClose={() => setOpenNav(false)} open={openNav} />
      <LayoutRoot>
        <LayoutContainer>
          <Outlet />
        </LayoutContainer>
      </LayoutRoot>
    </AuthGuard>
  );
};

export default DashboardLayout;
