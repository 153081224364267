import {jwtDecode} from "jwt-decode";

export const storeUserToken = (token: string) => {
  try {
    window.localStorage.setItem("AUTH-TOKEN-KEY", token);
  } catch (error) {
    console.log(error);
  }
};

export const getToken = (): string | null => {
  try {
    return window.localStorage.getItem("AUTH-TOKEN-KEY");
  } catch (error) {
    console.log(error);
    return null;
  }
};

export class Authorization {

  static isAuthenticated() {
    
    let token =  getToken();
    if(token && Authorization.decodeToken(token)){
       return token;
    }
    return token;
  }

  static decodeToken(token: string): any | null {
    try {
      const decodedToken: any = jwtDecode(token);
      // Check if the token has expired
      if (decodedToken.exp * 1000 < Date.now()) {
        // Token has expired
        return null;
      }
      return decodedToken;
    } catch (error) {
      console.log("Error decoding token:", error);
      return null;
    }
  }
}
Authorization.isAuthenticated()
