import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Scrollbar } from "../../components/scroll-bar";
import React from "react";
import { usePackages } from "../../hooks/use-orders";
import { SeverityPill } from "../../components/severity-pill";
import { paymentStatus, statusMap } from "../overview/overview-latest-orders";
import { useNavigate } from "react-router-dom";
import { paths } from "../../components/layout/dashboard/config";
import { useToast } from "../../hooks/use-toast";
import { PackageStatus } from "../../types";
import { formatTimeAgo } from "../../utils";

interface OrdersTableProps {
  onPageChange?: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  page: number;
  rowsPerPage: number;
  handleRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchQuery: string;
  filter?: PackageStatus
}

export const OrdersTable: React.FC<OrdersTableProps> = (props) => {
  const {
    onPageChange = () => { },
    page,
    rowsPerPage,
    handleRowsPerPageChange,
    searchQuery,
    filter
  } = props;

  const { data: orders, error } = usePackages(page, rowsPerPage, searchQuery, filter);
  const navigate = useNavigate();
  const { showToast } = useToast();
  React.useEffect(() => {
    if (error) {
      showToast({
        message: error.message,
        type: "error",
      });
    }
  }, [error, showToast]);
  return (
    <Card>
      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Order Number</TableCell>
                <TableCell>User</TableCell>
                <TableCell >Date</TableCell>
                <TableCell>Fee</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>No. Riders</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders &&
                orders.data.map((order, i) => {


                  return (
                    <TableRow hover key={i}>
                      <TableCell>{i + 1 + Math.max(0, page - 1) * rowsPerPage}</TableCell>
                      <TableCell>{order.package_number}</TableCell>
                      <TableCell>
                        {order.user?.first_name} {order.user?.last_name}
                      </TableCell>
                      <TableCell>{formatTimeAgo(order.created_at)}</TableCell>
                      <TableCell>{order.cost}</TableCell>

                      <TableCell>
                        <SeverityPill
                          color={
                            paymentStatus[
                            order.payment?.status ?? "PENDING"
                            ] as any
                          }
                        >
                          {order.payment?.status}
                        </SeverityPill>
                      </TableCell>
                      <TableCell>
                        <SeverityPill color={statusMap[order.status] as any}>
                          {order.status}
                        </SeverityPill>
                      </TableCell>
                      <TableCell>{order.broadcast_to.length}</TableCell>
                      <TableCell>
                        <Button
                          variant="text"
                          onClick={() => navigate(paths.orders + order.id)}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              <TableRow>
                <TablePagination
                  count={orders.count}
                  // convert page from and to zero indexed
                  onPageChange={(_, p) => onPageChange(_, p + 1)}
                  page={page - 1}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};
