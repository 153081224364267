import { createContext, useContext, useEffect, useState } from "react";
import { AccessToken, ErrorResponse, MessageResponse, User } from "../types";
import { useMutation } from "@tanstack/react-query";
import client from "../api";
import { useToast } from "../hooks/use-toast";
import { useNavigate } from "react-router-dom";
import { storeUserToken, Authorization } from "../utils/local-storage";

type Context = {
  login: (data: { phone: string; password: string }) => void;
  logout: () => void;
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User | null;
  loadingVerifyOTP: boolean;
  verifyOTP: (data: { phone: string; otp: string }) => void;
  requestOTP: (data: { phone: string; }) => void;
  loadingOTP: boolean;
  otpSent: boolean;

};
export const AuthContext = createContext<Context>({
  login: (_data: { phone: string; password: string }) => { },
  verifyOTP: (_data: { phone: string; otp: string }) => { },
  requestOTP: (_data: { phone: string; }) => { },
  logout: () => { },
  isAuthenticated: false,
  isLoading: false,
  user: null,
  loadingVerifyOTP: false,
  loadingOTP: false,
  otpSent:false,

});

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [state, setState] = useState<{
    user: User | null;
    isAuthenticated: boolean;
    otpSent: boolean;
  }>({
    isAuthenticated: !!Authorization.isAuthenticated(),
    user: null,
    otpSent: false,
  });
  const { isLoading, mutate: login } = useMutation<
    MessageResponse,
    ErrorResponse,
    { phone: string; password: string }
  >({
    mutationFn: ({ phone, password }) => client.users.login(phone, password),
    onError: (error) => {
      showToast({
        message: error.message,
        type: "error",
      });
    },
    onSuccess: (data) => {
      showToast({
        message: data.message,
        type: "success",
      });
      setState({
        ...state, otpSent: true
      })
    },
    onMutate: () => {
      showToast({
        message: "Submitting, please wait..",
        type: "success",
      });
    },
  });

  const { isLoading: loadingVerifyOTP, mutate: verifyOTP } = useMutation<
    AccessToken,
    ErrorResponse,
    { phone: string; otp: string }
  >({
    mutationFn: ({ phone, otp }) => client.users.verifyOTP(phone, otp),
    onError: (error) => {
      showToast({
        message: error.message,
        type: "error",
      });
    },
    onSuccess: (data) => {
      setState({ ...state, isAuthenticated: true });

      storeUserToken(data.access_token);
      navigate("/");

      client.users
        .profile()
        .then((_user) => {
          setState({ ...state, isAuthenticated: true });
        })
        .catch((e: ErrorResponse) => {
          console.log(e);
          showToast({
            message: e.message ?? "Failed to get your profile",
            type: "error",
          });
        });
      return data;
    },
    onMutate: () => {
      showToast({
        message: "Submitting, please wait..",
        type: "success",
      });
    },
  });

  const { isLoading: loadingOTP, mutate: requestOTP } = useMutation<
    MessageResponse,
    ErrorResponse,
    { phone: string; }
  >({
    mutationFn: ({ phone }) => client.users.requestOTP(phone),
    onError: (error) => {
      showToast({
        message: error.message,
        type: "error",
      });
    },
    onSuccess: (data) => {
      showToast({
        message: data.message,
        type: "success",
      });
      setState({
        ...state, otpSent: true
      })
    },
    onMutate: () => {
      showToast({
        message: "Submitting, please wait..",
        type: "success",
      });
    },
  });

  const logout = () => {
    setState({
      ...state,
      isAuthenticated: false,
      user: null,
    });
    localStorage.clear();
  };
  useEffect(() => {
    if (Authorization.isAuthenticated()) {
      setState((prevState) => ({
        ...prevState,
        isAuthenticated: true,
      }));
      client.users
        .profile()
        .then((user) => {
          setState((prevState) => ({
            ...prevState,
            user,
            isAuthenticated: true,
          }));
        })
        .catch((e: ErrorResponse) => {
          showToast({
            message: e.message ?? "Failed to get your profile",
            type: "error",
          });
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        isLoading,
        login,
        logout,
        loadingOTP,
        loadingVerifyOTP,
        requestOTP,
        verifyOTP

      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): Context => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};

export const AuthConsumer = AuthContext.Consumer;
