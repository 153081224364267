import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Grid,
  Checkbox,
} from "@mui/material";
import { Scrollbar } from "../components/scroll-bar";
import {
  useCourier,
  useDeleteCourierDetail,
  useUpdateCourier,
} from "../hooks/use-couriers";
import { Courier, CourierPackageDetail } from "../types";
import { useToast } from "../hooks/use-toast";
import { useParams } from "react-router-dom";

export const CourierDetail = () => {
  const { id } = useParams();
  const { data: courier, error } = useCourier(id as any);
  const { mutate: updateCourier } = useUpdateCourier();
  const { mutate: deleteCategory } = useDeleteCourierDetail(id as any);

  const [courierToUpdate, setCourierToUpdate] = useState<Courier | null>(
    courier as any
  );

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type, checked } = e.target as any;
    const updatedValue = type === "checkbox" ? checked : value;
    setCourierToUpdate((prevCourier) => ({
      ...prevCourier!,
      [name]: updatedValue,
    }));
  };

  const handleUpdateCourier = (e: any) => {
    e.preventDefault();
    if (courierToUpdate) {
      updateCourier({
        id: id as any,
        data: courierToUpdate,
      });
    }
  };

  const handleSaveDetail = (updatedDetail: CourierPackageDetail) => {
    updateCourier({
      id: id as any,
      data: {
        details: [updatedDetail],
      },
    });
  };
  const handleCreateDetail = (create: Partial<CourierPackageDetail>) => {
    updateCourier({
      id: id as any,
      data: {
        details: [create as any],
      },
    });
  };
  const handleDeleteDetail = (id: number) => {
    deleteCategory({
      id,
    });
  };

  const { showToast } = useToast();
  React.useEffect(() => {
    if (error) {
      showToast({
        message: error.message,
        type: "error",
      });
    }
  }, [error, showToast]);

  React.useEffect(() => {
    setCourierToUpdate(courier as any);
  }, [courier]);

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">{courier?.type} Details</Typography>
              </Stack>
            </Stack>

            {/* Section for updating the courier */}
            <Card>
              <form onSubmit={handleUpdateCourier}>
                <Stack spacing={3} p={3}>
                  <Typography variant="h5">Update Courier</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Courier Type"
                        name="type"
                        value={courierToUpdate?.type}
                        fullWidth
                        helperText="Type of courier"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Fee Percentage (%)"
                        name="fee_percentage"
                        value={courierToUpdate?.fee_percentage ?? ""}
                        type="number"
                        onChange={handleInputChange}
                        fullWidth
                        required
                        helperText="Fee percentage charged for each courier per delivery"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Base Distance (km)"
                        name="base_distance"
                        value={courierToUpdate?.base_distance ?? ""}
                        type="number"
                        onChange={handleInputChange}
                        fullWidth
                        required
                        helperText="Maximum distance that will be charged under base price"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Maximum Distance (km)"
                        name="max_distance"
                        value={courierToUpdate?.max_distance ?? ""}
                        type="number"
                        onChange={handleInputChange}
                        fullWidth
                        required
                        helperText="Maximum distance a courier can travel"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Checkbox
                        name="available"
                        checked={courierToUpdate?.available ?? false}
                        onChange={handleInputChange}
                      />
                      Available
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                      <Button variant="contained" type="submit">
                        Update Courier
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Card>

            {/* Courier details table */}
            <Card>
              <Scrollbar>
                <Box sx={{ minWidth: 800 }}>
                  <Typography variant="h5" sx={{ m: 3 }}>
                    Courier Parcel Category Pricing
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Max Weight (kg)</TableCell>
                        <TableCell>Min Weight (kg)</TableCell>
                        <TableCell>Price (ksh)</TableCell>
                        <TableCell>Base Price (ksh)</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {courier &&
                        courier.details.map((detail,i) => (
                          <CourierTableRow
                            key={i}
                            detail={detail}
                            onDelete={handleDeleteDetail}
                            onSave={handleSaveDetail}
                          />
                        ))}
                      <CourierCreateCategoryTableRow
                        handleCreate={handleCreateDetail}
                      />
                    </TableBody>
                  </Table>
                </Box>
              </Scrollbar>
            </Card>
          </Stack>
        </Container>
      </Box>
    </>
  );
};
interface CourierTableRowProps {
  detail: CourierPackageDetail;
  onSave: (updatedDetail: CourierPackageDetail) => void;
  onDelete: (id: number) => void;
}

function CourierTableRow(props: CourierTableRowProps) {
  const { detail, onSave, onDelete } = props;
  const [editedDetail, setEditedDetail] = useState({ ...detail });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedDetail({ ...editedDetail, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    onSave(editedDetail);
  };

  return (
    <TableRow >
      <TableCell sx={{ p: 1 }}>
        <TextField
          name="name"
          value={editedDetail.name}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell sx={{ p: 1 }}>
        <TextField
          name="max_weight"
          type="number"
          value={editedDetail.max_weight}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell sx={{ p: 1 }}>
        <TextField
          name="min_weight"
          type="number"
          value={editedDetail.min_weight}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell sx={{ p: 1 }}>
        <TextField
          name="price"
          type="number"
          value={editedDetail.price}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell sx={{ p: 1 }}>
        <TextField
          name="base_price"
          type="number"
          value={editedDetail.base_price}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell>
        <Button variant="text" color="primary" onClick={handleSave}>
          Save
        </Button>
        <Button
          variant="text"
          color="error"
          onClick={() => onDelete(detail.id)}
        >
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
}

function CourierCreateCategoryTableRow({
  handleCreate,
}: {
  handleCreate: (courier: Partial<CourierPackageDetail>) => void;
}) {
  const [editedDetail, setEditedDetail] = useState<any>({
    name: "",
    max_weight: "",
    min_weight: "",
    base_price: "min_weight",
    price: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedDetail({ ...editedDetail, [e.target.name]: e.target.value });
  };

  const create = () => {
    handleCreate(editedDetail);
    setEditedDetail({
      name: "",
      max_weight: "",
      min_weight: "",
      base_price: "min_weight",
      price: "",
    });
  };

  return (
    <TableRow >
      <TableCell sx={{ p: 1 }}>
        <TextField
          name="name"
          value={editedDetail.name}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell sx={{ p: 1 }}>
        <TextField
          name="max_weight"
          type="number"
          value={editedDetail.max_weight}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell sx={{ p: 1 }}>
        <TextField
          name="min_weight"
          type="number"
          value={editedDetail.min_weight}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell sx={{ p: 1 }}>
        <TextField
          name="price"
          type="number"
          value={editedDetail.price}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell sx={{ p: 1 }}>
        <TextField
          name="base_price"
          type="number"
          value={editedDetail.base_price}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell>
        <Button variant="text" color="primary" onClick={create}>
          Create New
        </Button>
      </TableCell>
    </TableRow>
  );
}
export default CourierDetail;
