import { FormEvent, useState } from "react";
import { Box, Button, Link, Stack, TextField, Typography } from "@mui/material";
import { useAuth } from "../hooks/auth";
import { AuthLayout } from "../components/layout/auth";

const Login: React.FC = () => {
  const { login, isLoading, otpSent, verifyOTP, loadingOTP, loadingVerifyOTP, requestOTP, } = useAuth();
  const [state, setState] = useState({
    phone: "",
    password: "",
    code: ""
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const formSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (otpSent) {
      verifyOTP({
        otp: state.code, phone: state.phone
      })
    }
    else {

      login({ password: state.password, phone: state.phone });
    }
  };

  return (
    <AuthLayout>
      <Box
        sx={{
          flex: "1 1 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",

        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: "100px",
            width: "100%",
          }}
        >
          <div>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Typography variant="h4">Login</Typography>
            </Stack>
            <form onSubmit={formSubmit}>
              <Stack spacing={3}>
                <TextField
                  value={state.phone}
                  fullWidth
                  label="Phone Number"
                  name="phone"
                  type="phone"
                  required
                  onChange={onChange}
                />
                {
                  otpSent ? <TextField
                    fullWidth
                    label="Verification Code"
                    name="code"
                    type="text"
                    required
                    value={state.code}
                    onChange={onChange}
                  /> :
                    <TextField
                      fullWidth
                      label="Password"
                      name="password"
                      type="password"
                      required
                      onChange={onChange}
                    />
                }
                {otpSent &&
                  <Typography color="text.secondary" variant="body2" sx={{ mt: 2 }}>
                    You did not receive the verification code? &nbsp;
                    <Link
                      onClick={(e) => {
                        e.preventDefault()
                        requestOTP({ phone: state.phone });
                      }
                      }
                      href="#"
                      underline="hover"
                      variant="subtitle2"
                    >
                      Resend
                    </Link>
                  </Typography>
                }
              </Stack>
              <Button
                fullWidth
                size="large"
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
                disabled={isLoading || loadingOTP || loadingVerifyOTP}
              >
                {otpSent ? "Verify" : "Login"}
              </Button>

              <Typography color="text.secondary" variant="body2" sx={{ mt: 2 }}>
                Forgot password? &nbsp;
                <a
                  href="https://gatyce.com/password-reset"
                >
                  Reset Password
                </a>
              </Typography>
            </form>
          </div>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default Login;
