import { format } from "date-fns";
import {
  Avatar,
  Box,
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Scrollbar } from "../../components/scroll-bar";
import {
  useRiders,
 
} from "../../hooks/use-users";
import React from "react";
import { useToast } from "../../hooks/use-toast";
import { CourierType } from "../../types";
import { useNavigate } from "react-router-dom";

interface RidersTableProps {
  searchQuery?: string;
  filter?: CourierType;
}

export const RidersTable: React.FC<RidersTableProps> = (props) => {
  const { data: users, isLoading, error } = useRiders(props.searchQuery, props.filter);
  const navigate = useNavigate()



  const { showToast } = useToast();
  React.useEffect(() => {
    if (error) {
      showToast({
        message: error.message,
        type: "error",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  return (
    <Card>

      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Signed Up</TableCell>
                <TableCell>Last Active</TableCell>
                <TableCell >Courier</TableCell>
                <TableCell padding="checkbox">Verified</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <p></p>
              ) : (
                users.map((rider, i) => {
                  const isSelected = false;
                  const createdAt = format(
                    Date.parse(rider.user.created_at),
                    "dd/MM/yyyy"
                  );
                  const lastActive = rider.user.last_active
                    ? format(
                      Date.parse(rider.user.last_active),
                      "dd/MM/yyyy HH:mm"
                    )
                    : "None";

                  return (
                    <TableRow hover key={i} selected={isSelected}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>
                        <Stack alignItems="center" direction="row" spacing={2}>
                          <Avatar src={rider.user.profile_image ?? ""}>
                            {rider.user.first_name.charAt(0)}
                          </Avatar>
                          <Typography variant="subtitle2">
                            {rider.user.first_name} {rider.user.last_name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>{rider.user.email}</TableCell>
                      <TableCell>{rider.user.phone_number}</TableCell>
                      <TableCell>{createdAt}</TableCell>
                      <TableCell>{lastActive}</TableCell>
                      <TableCell >
                        {rider.courier_type}
                      </TableCell>
                      <TableCell padding="checkbox">
                        {rider.verified ? "Yes" : "No"}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="text"
                          onClick={() => navigate(`/riders/${rider.id}/`)}
                        >
                          View
                        </Button>
                        <Button
                          variant="text"
                          onClick={() => navigate(`/riders/${rider.id}/earning`)}
                        >
                          Earnings
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};
