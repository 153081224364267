import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Scrollbar } from "../../components/scroll-bar";
import React, { useState } from "react";
import { useToast } from "../../hooks/use-toast";
import { Transaction } from "../../types";
import { useTransactions } from "../../hooks/use-transactions";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { SeverityPill } from "../../components/severity-pill";
import { paymentStatus } from "../overview/overview-latest-orders";

interface TransactionsTableProps {
  onPageChange?: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  page: number;
  rowsPerPage: number;
  handleRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchQuery?: string;
}

export const TransactionsTable: React.FC<TransactionsTableProps> = ({
  page,
  rowsPerPage,
  searchQuery,
  handleRowsPerPageChange,
  onPageChange,
}) => {
  const {
    data: transactions,
    isLoading,
    error,
  } = useTransactions(page, rowsPerPage, searchQuery);
  const [selected, setSelected] = useState<Transaction | null>(null);
  const navigate = useNavigate()

  const { showToast } = useToast();

  React.useEffect(() => {
    if (error) {
      showToast({
        message: error.message,
        type: "error",
      });
    }
  }, [error, showToast]);

  return (
    <Card>

      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Payment Mode</TableCell>
                <TableCell>Debited Account</TableCell>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Account Reference</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Transaction Type</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={11}>Loading...</TableCell>
                </TableRow>
              ) : (
                transactions?.data.map((transaction, i) => {
                  const isSelected = selected === transaction;
                  const createdAt = format(
                    Date.parse(transaction.created_at),
                    "dd/MM/yyyy HH:mm"
                  );
                  const updatedAt = format(
                    Date.parse(transaction.updated_at),
                    "dd/MM/yyyy HH:mm"
                  );

                  return (
                    <TableRow hover key={transaction.id} selected={isSelected}>
                      <TableCell>{i + 1 + Math.max(0, page - 1) * rowsPerPage}</TableCell>
                      <TableCell>{transaction.payment_mode}</TableCell>
                      <TableCell>{transaction.account_debited}</TableCell>
                      <TableCell>{transaction.mpesa_transaction_id}</TableCell>
                      <TableCell>{transaction.account_reference}</TableCell>
                      <TableCell>
                        <SeverityPill
                          color={
                            paymentStatus[
                            transaction.status ?? "PENDING"
                            ] as any
                          }
                        >
                          {transaction.status}
                        </SeverityPill>
                      </TableCell>
                      <TableCell>{createdAt}</TableCell>
                      <TableCell>{updatedAt}</TableCell>
                      <TableCell>{transaction.amount}</TableCell>
                      <TableCell>{transaction.transaction_type}</TableCell>
                      <TableCell>
                        <Button
                          variant="text"
                          onClick={() => {
                            setSelected(transaction)
                            navigate(`/transactions/${transaction.id}`)
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
              <TableRow>

                <TablePagination
                  count={transactions.count}
                  // convert page from and to zero indexed
                  onPageChange={(_, p) => onPageChange!(_, p + 1)}
                  page={page - 1}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        {transactions?.data.length === 0 && !isLoading && (
          <Typography variant="body1" align="center">
            No transactions found.
          </Typography>
        )}
      </Scrollbar>
    </Card>
  );
};
