import { Alert, AlertColor, Box } from "@mui/material";
import React, { useState } from "react";

interface Toast {
  id: number;
  message: string;
  severity: AlertColor;
  autoDismiss: boolean;
  dismissTime: number;
}

interface ToastProps {
  message: string;
  type?: AlertColor;
  autoDismiss?: boolean;
  dismissTime?: number;
}
export interface ToastContextProps {
  showToast: (options: ToastProps) => void;
  dismissToast: (id: number) => void;
  toasts: Toast[];
}

export const ToastContext = React.createContext<ToastContextProps | undefined>(
  undefined
);

export default function ToastProvider({ children }: React.PropsWithChildren) {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const showToast = ({
    message,
    type = "info",
    autoDismiss = true,
    dismissTime = 10000,
  }: ToastProps) => {
    const toast = {
      id: Date.now(),
      message,
      severity: type,
      autoDismiss,
      dismissTime,
    };
    if (toasts.some((toast) => toast.message === message)) {
      return;
    }
    setToasts((prevToasts) => [...prevToasts, toast]);
    if (autoDismiss) {
      setTimeout(() => {
        dismissToast(toast.id);
      }, dismissTime);
    }
  };
  const dismissToast = (id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };


  const contextValue: ToastContextProps = {
    showToast,
    dismissToast,
    toasts,
  };
  return (
    <ToastContext.Provider value={contextValue}>
      <Box
        position="fixed"
        top={16}
        right={16}
        zIndex={9999}
        display="flex"
        justifyContent="center"
      >
        <Box maxWidth={1200} width="100%">
          {toasts.map((toast) => (
            <Alert
              key={toast.id}
              severity={toast.severity}
              onClose={() => dismissToast(toast.id)}
              sx={{ marginBottom: "10px" }}
            >
              {toast.message}
            </Alert>
          ))}
        </Box>
      </Box>
      {children}
    </ToastContext.Provider>
  );
}
