import { useCallback, useState } from "react";
import {
  Box,
  Card,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";

import { OrdersTable } from "../sections/orders/order-table";
import { PackageStatus } from "../types";
import MagnifyingGlassIcon from "@mui/icons-material/Search";

const OrdersPage = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearch] = useState("");
  const [filter, setFilter] = useState<any>("All");


  const handleFilterChange = (event: any) => {
    setFilter(event.target.value as any);
  };

  const handlePageChange = useCallback((event: any, value: number) => {
    setPage(value);
  }, []);

  const handleRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value));
    },
    []
  );
  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Orders </Typography>

              </Stack>
            </Stack>
            <Card sx={{ p: 2 }}>
              <Stack direction="row" justifyContent="space-between" spacing={4} width="100%">
                <OutlinedInput
                  onChange={handleSearch}
                  defaultValue=""
                  fullWidth
                  placeholder="Search by package number"
                  startAdornment={
                    <InputAdornment position="start">
                      <SvgIcon color="action" fontSize="small">
                        <MagnifyingGlassIcon />
                      </SvgIcon>
                    </InputAdornment>
                  }
                  sx={{ maxWidth: 500 }}
                />
                <FormControl sx={{
                  minWidth: "150px"
                }}>
                  <InputLabel id="filter-label">Courier Type</InputLabel>
                  <Select
                    labelId="filter-label"
                    id="filter"
                    value={filter || ""}
                    onChange={handleFilterChange}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {Object.values(PackageStatus).map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Card>
            <OrdersTable
              onPageChange={handlePageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              handleRowsPerPageChange={handleRowsPerPageChange}
              searchQuery={searchQuery}
              filter={filter === "All" ? undefined : filter}
            />
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default OrdersPage;
