import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  SvgIcon,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import Chart from "react-apexcharts";
import { paths } from "../../components/layout/dashboard/config";
import { useNavigate } from "react-router-dom";

interface OverviewFinancialProps {
  ordersTotalValueData: number[];
  profitOverviewsData: number[];
  dates: string[];
  sx?: object;
}

const useChartOptions = () => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: [theme.palette.success.main, theme.palette.error.main],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 0.1,
      type: "solid",
    },
    stroke: {
      colors: [theme.palette.success.main, theme.palette.error.main],
      show: true,
      width: 2,
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: true,
    },
    plotOptions: {
      bar: {
        columnWidth: "40px",
      },
    },

    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      categories: [],
      labels: {
        offsetY: 5,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value: any) => (value > 1000 ? `${value / 1000}K` : `${value}`),
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };
};

export const FinancialOverview: React.FC<OverviewFinancialProps> = (props) => {
  const { ordersTotalValueData, profitOverviewsData, dates, sx } = props;
  const chartOptions = useChartOptions();
  const navigate = useNavigate()
  chartOptions.xaxis.categories = dates as any;

  return (
    <Card sx={sx}>
      <CardHeader
        action={
          <Button
            color="inherit"
            size="small"
            startIcon={
              <SvgIcon fontSize="small">
                <TrendingUpIcon />
              </SvgIcon>
            }
          >
            Sync
          </Button>
        }
        title="Financial"
      />
      <CardContent>
        <Chart
          height={350}
          options={chartOptions}
          // Update series data with the provided data
          series={[
            {
              name: "Orders Total Value",
              data: ordersTotalValueData,
            },
            {
              name: "Profit Overviews",
              data: profitOverviewsData,
            },
          ]}
          type="area"
          width="100%"
        />
      </CardContent>
      <Divider />
      <CardActions
        onClick={() => navigate(paths.transactions)}
        sx={{ justifyContent: "flex-end" }}>
        <Button
          color="inherit"
          endIcon={
            <SvgIcon fontSize="small">
              <ArrowForwardIcon />
            </SvgIcon>
          }
          size="small"
        >
          Overview
        </Button>
      </CardActions>
    </Card>
  );
};
