import { Box, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import Logo from "../../logo";

interface AuthLayoutProps extends React.PropsWithChildren {}

export const AuthLayout = (props: AuthLayoutProps) => {
  const { children } = props;

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flex: "1 1 auto",
        minHeight: "100vh",
      }}
    >
      <Grid container sx={{ flex: "1 1 auto" }}>
        <Grid
          xs={12}
          lg={8}
          sx={{
            backgroundColor: "background.paper",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};
