import MagnifyingGlassIcon from "@mui/icons-material/Search";
import { Card, InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";

export const TransactionSearch = ({
  handleSearch,
}: {
  handleSearch: (e: any) => void;
}) => (
  <Card sx={{ p: 2 }}>
    <OutlinedInput
      onChange={handleSearch}
      defaultValue=""
      fullWidth
      placeholder="Enter transaction number or account reference"
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <MagnifyingGlassIcon />
          </SvgIcon>
        </InputAdornment>
      }
      sx={{ maxWidth: 500 }}
    />
  </Card>
);
