import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Authorization, getToken } from "../utils/local-storage";

// export const BASE_URL = "http://localhost:8000";
export const BASE_URL = "https://api.gatyce.com";
const Axios: AxiosInstance = axios.create({
  baseURL: BASE_URL,
});

Axios.interceptors.request.use((config: any) => {
  const authToken = getToken();

  config.headers!.Authorization = `Bearer ${authToken}`;

  return config;
});

Axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError<any>) => {
    if (error.response?.status === 401 ) {
      const authEvent = new CustomEvent('unauthorized', { detail: 'User unauthorized' });
      window.dispatchEvent(authEvent);
      localStorage.clear()
    }
    
  
  // For consistency, set up a default response in case of an error
  error.message = error.response?.data.message ?? error.message??"An error occurred";
  error.response = {
    data: { message: error.response?.data.error ?? error.message },
    status: error.status || 500,
    statusText: error.response?.statusText as any || 'Internal Server Error',
    headers: {},
    config: error.config || {} as any 
  };

  return Promise.reject(error);
  }
);

export class Http {
  static async get<T>(
    url: string,
    params?: any,
    headers?: AxiosHeaders
  ): Promise<T> {
    try {
      const config: AxiosRequestConfig = { params, headers };
      const res = await Axios.get<T>(url, config);
      return res.data;
    } catch (e: any) {
      throw e.response?.data ?? e.response ?? e;
    }
  }

  static async post<T>(
    url: string,
    data?: any,
    params?: any,
    headers?: any
  ): Promise<T> {
    try {
      const config: AxiosRequestConfig = { params, headers };
      const res = await Axios.post<T>(url, data, config);
      return res.data;
    } catch (e: any) {
      throw e.response?.data ?? e.response ?? e;
    }
  }

  static async put<T>(
    url: string,
    data?: any,
    params?: any,
    headers?: any
  ): Promise<T> {
    try {
      const config: AxiosRequestConfig = { params, headers };
      const res = await Axios.put<T>(url, data, config);
      return res.data;
    } catch (e: any) {
      throw e.response?.data ?? e.response ?? e;
    }
  }

  static async delete<T>(url: string, params?: any, headers?: any): Promise<T> {
    try {
      const config: AxiosRequestConfig = { params, headers };
      const res = await Axios.delete<T>(url, config);
      return res.data;
    } catch (e: any) {
      throw e.response?.data ?? e.response ?? e;
    }
  }
}
