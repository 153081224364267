import React from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { CourierType, Rider, User } from "../../types";
import AssignDelivery from "./assign-to-rider";

const OrderUserDetail = ({ user }: { user: User }) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Typography variant="h6" component="div">
          User Details
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Name"
              secondary={user.first_name + " " + user.last_name}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Phone Number"
              secondary={user.phone_number}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Email" secondary={user.email} />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};
export const OrderRiderDetail = ({ user, canAssign, courierType, onAssign }: {
  user?: Rider | null, canAssign: boolean,
  courierType: CourierType,
  onAssign: (riderId: number) => void
}) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Typography variant="h6" component="div">
          Rider Details
        </Typography>
        <List>
          {!user ? (
            <ListItem>
              <ListItemText secondary="No rider is assigned to this order yet" />
            </ListItem>
          ) : (
            <>
              <ListItem>
                <ListItemText
                  primary="Name"
                  secondary={user.user.first_name + " " + user.user.last_name}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Phone Number"
                  secondary={user.user.phone_number}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Email" secondary={user.user.email} />
              </ListItem>
            </>
          )}
        </List>
        {canAssign && <AssignDelivery courierType={courierType} onAssign={onAssign} />

        }
      </CardContent>
    </Card>
  );
};

export default OrderUserDetail;
