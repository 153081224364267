import { Box, Container, Typography, Card, TextField, Button, FormControl, InputLabel, Select, MenuItem, ListItemText, ListItem, List } from '@mui/material';
import { useEffect, useState } from 'react';
import { Transaction, PaymentStatus } from '../types';
import client from '../api';
import { useParams } from 'react-router-dom';
import { useToast } from "../hooks/use-toast";
import { format } from 'date-fns';
import { SeverityPill } from '../components/severity-pill';
import { paymentStatus } from '../sections/overview/overview-latest-orders';

const TransactionDetails = () => {
    const [transaction, setTransaction] = useState<Transaction | null>(null);
    const [loading, setLoading] = useState(true);
    const [updatedStatus, setUpdatedStatus] = useState<PaymentStatus | ''>('');
    const [updatedDetails, setUpdatedDetails] = useState<string>('');
    const [updatedTransactionId, setUpdatedTransactionId] = useState<string | null | undefined>('');
    const { id } = useParams();
    const { showToast } = useToast();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await client.transactions.transactionDetails(
                    id as any
                )
                setTransaction(data);
            } catch (error: any) {
                showToast({
                    message: error.message,
                    type: "error",
                });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        setUpdatedStatus(transaction?.status ?? "")
        setUpdatedTransactionId(transaction?.mpesa_transaction_id ?? "")
        setUpdatedDetails(transaction?.additional_details ?? "")

    }, [transaction])
    const handleUpdate = async () => {
        try {
            const isStatusChanged = updatedStatus && updatedStatus !== transaction?.status;

            if (isStatusChanged) {
                // Show confirmation dialog
                const confirmed = window.confirm(
                    `Are you sure you want to update the transaction status?`
                );

                // If not confirmed, return without updating
                if (!confirmed) {
                    return;
                }
            }
            showToast({
                message: "Updating please wait...",
                type: "success",
            });
            const data = await client.transactions.updateTransaction(id as any, {
                additional_details: updatedDetails,
                mpesa_transaction_id: updatedTransactionId,
                ...(updatedStatus && updatedStatus !== transaction?.status
                    ? { status: updatedStatus }
                    : {}),
            } as any);
            setTransaction(data);
            showToast({
                message: "Transaction details updated successfully!",
                type: "success",
            });
        } catch (error: any) {
            showToast({
                message: error.message,
                type: "error",
            });
        }
    };

    const reverseTransaction = async () => {
        try {
            showToast({
                message: "Updating please wait...",
                type: "success",
            });
            const data = await client.transactions.reverseTransaction(id as any);
            setTransaction(data);
            showToast({
                message: data.status === "REVERSED" ? "This transaction has already been reversed" : "Reversal request initiated, please wait for mpesa confirmation",
                type: "success",
            });
        } catch (error: any) {
            showToast({
                message: error.message,
                type: "error",
            });
        }
    }

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8
                }}
            >
                <Container maxWidth="lg">
                    <Typography variant="h4" mb={4}>
                        Transaction Details
                    </Typography>
                    <Card sx={{ p: 2 }}>
                        {loading ? (
                            <Typography>Loading...</Typography>
                        ) : (
                            <div>

                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary="Payment Mode"
                                            secondary={transaction?.payment_mode}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Account Debited"
                                            secondary={transaction?.account_debited}
                                        />
                                    </ListItem>

                                    <ListItem>
                                        <ListItemText
                                            primary="Transaction ID (Mpesa Receipt Number)"
                                            secondary={transaction?.mpesa_transaction_id || 'N/A'}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Account Reference"
                                            secondary={transaction?.account_reference}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Status"
                                            secondary={<SeverityPill
                                                color={paymentStatus[transaction?.status ?? "PENDING"] as any}
                                            >
                                                {transaction?.status}
                                            </SeverityPill>}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Created At"
                                            secondary={format(
                                                Date.parse(transaction!.created_at),
                                                "dd/MM/yyyy HH:mm"
                                            )}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Updated At"
                                            secondary={format(
                                                Date.parse(transaction!.updated_at),
                                                "dd/MM/yyyy HH:mm"
                                            )}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Amount"
                                            secondary={transaction?.amount}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Additional Details"
                                            secondary={transaction?.additional_details || 'N/A'}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Transaction Description"
                                            secondary={transaction?.transaction_description || 'N/A'}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Transaction Type"
                                            secondary={transaction?.transaction_type}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="User"
                                            secondary={transaction?.user ? `${transaction?.user?.first_name} ${transaction?.user?.last_name}` : 'N/A'}
                                        />
                                    </ListItem>
                                </List>

                                {
                                    (transaction?.status === PaymentStatus.COMPLETED && transaction?.transaction_type === "DEPOSIT") &&
                                    <Button sx={{my:"1rem"}} color='warning' variant='outlined' onClick={reverseTransaction}>Reverse This Transaction</Button>
                                }

                                <Typography variant="h6" component="div">
                                    Update Transaction Status
                                </Typography>

                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="status-label">Update Status</InputLabel>
                                    <Select
                                        labelId="status-label"
                                        id="status"
                                        value={updatedStatus}
                                        onChange={(e) => setUpdatedStatus(e.target.value as PaymentStatus)}
                                    >
                                        <MenuItem value={'PENDING'}>PENDING</MenuItem>
                                        <MenuItem value={'COMPLETED'}>COMPLETED</MenuItem>
                                        <MenuItem value={'FAILED'}>FAILED</MenuItem>
                                        <MenuItem value={'REVERSED'}>REVERSED</MenuItem>
                                    </Select>
                                </FormControl>

                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Update Additional Details"
                                    value={updatedDetails}
                                    onChange={(e) => setUpdatedDetails(e.target.value)}
                                />

                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Update Transaction ID (Mpesa Receipt Number)"
                                    value={updatedTransactionId || ''}
                                    onChange={(e) => setUpdatedTransactionId(e.target.value)}
                                    required
                                />

                                <Button
                                    variant="contained"
                                    onClick={handleUpdate}
                                    sx={{ mt: 2 }}
                                >
                                    Update Transaction
                                </Button>
                            </div>
                        )}
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default TransactionDetails;
