import { Typography } from "@mui/material";
import React from "react";
import logo from "../assets/logo.png";

export default function Logo() {
  return (
    <Typography
      variant="h1"
      sx={{
        color: "white",
      }}
    >
      <img height={64}  src={logo} alt="courier logo" />
    </Typography>
  );
}
