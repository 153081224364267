export interface AccessToken {
  access_token: string;
  refresh_token: string;
}

export enum CourierType {
  Lorry = "Lorry",
  Pickup = "Pickup",
  Motorbike = "Motorbike", 
  TukTuk = "TukTuk",
  Bicycle = "Bicycle",

}

export interface CourierPackageDetail {
  name: string;
  max_weight: number;
  min_weight: number;
  price: number;
  base_price?: number;
  id: number;
  courier:Courier
}

export interface Courier {
  type: CourierType;
  id: number;
  icon: string;
  details: CourierPackageDetail[];
  available: boolean;
  base_distance: number;
  max_distance: number;
  fee_percentage: number;
}

export enum RiderPaymentStatus {
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}
export type RiderEarning = {
  created_at: string;
  id: null | number;
  amount: number;
  approval_status: null | RiderPaymentStatus;
  completed_at: null | string;
  earning_overview: EarningOverview[];
};

export type EarningOverview = {
  rider_payment_id: number;
  delivery_id: number;
  paid: boolean;
  effective_amount: number;
  description: string;
  id: number;
};

export enum PaymentStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  REVERSED = "REVERSED",
  PARTIAL = "PARTIAL",
}

export type Transaction = {
  payment_mode: string;
  account_debited: string;
  mpesa_transaction_id?: string | null;
  account_reference: string;
  status: PaymentStatus;
  created_at: string;
  updated_at: string;
  amount: number;
  additional_details?: string | null;
  transaction_type: string;
  transaction_description?: string;
  id: number;
  user?: User;
};
export interface User {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  phone_number_1?: string | null;
  created_at: string;
  profile_image?: string | null;
  phone_verified: boolean;
  email_verified: boolean;
  id: number;
  is_admin: boolean;
  last_active: string | null;
}

export interface Rider {
  id: number;
  verified: boolean;
  rider_id_image: string;
  kra_image: string;
  good_conduct_certificate_image: string;
  courier_type: CourierType;
  courier_owner_id_image?: string | null;
  logbook_image?: string | null;
  driver_license_image?: string | null;
  motorcycle_image?: string | null;
  user: User;
  paid_verification: boolean;
  requested_documents:{
    name:string,
    label:string,
    description:string
  }[]
}
export enum PackageStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  PICKED = "PICKED",
  RIDER_CANCELLED = "RIDER_CANCELLED",
  CUSTOMER_CANCELLED = "CUSTOMER_CANCELLED",
  ADMIN_CANCELLED = "ADMIN_CANCELLED",
  REACHED_DESTINATION = "REACHED_DESTINATION",
  DELIVERY_ATTEMPTED = "DELIVERY_ATTEMPTED",
  DELIVERED = "DELIVERED",
  ADMIN_CONFIRMED = "ADMIN_CONFIRMED",
}
export interface Address {
  latitude: number;
  longitude: number;
  full_name: string;
  street_address: string;
  city: string;
  phone_number: string;
  phone_number_1?: string | null;
  id: number;
}
export interface DeliveryTimeline {
  package_id: number;
  message: string;
  created_at: string;
}

export interface Package {
  id: number;
  pickup_address: Address;
  delivery_address: Address;
  delivery_timelines: DeliveryTimeline[];
  category_id: number;
  package_number: string;
  created_at: string;
  cost: number;
  distance: number;
  status: PackageStatus;
  nature: string;
  payment?: Transaction;
  package_pickup_image: string;
  package_dropoff_image?: string;
  signature_image?: string;
  item_name?: string;
  monetary_value?: number;
  recipient_id_number?: string;
  user: User;
  rider?: Rider;
  broadcast_to: Rider[];
  rider_earning: number;
  commission: number;
  feedback?:string;
  category: CourierPackageDetail;
}

export interface MessageResponse {
  message: string;
  details?: Record<string, any>;
}

export interface ErrorResponse {
  message: string;
  detail: Record<string, Record<string, string>>;
}

export interface Paginator<T> {
  page: number;
  data: T[];
  pages: number;
  count:number;
}
export interface Feedback {
  user: User;
  user_id: number;
  id: number;
  subject: string;
  created_at: string;
  message: string;
}

export interface DashboardOverviewData {
  users: {
    total: number;
    this_month: number;
  };
  riders: {
    total: number;
    this_month: number;
  };
  orders: {
    total: number;
    this_month: number;
  };
  revenue: {
    total: number;
    this_month: number;
  };
  order_total_amount: {
    total: number;
    this_month: number;
  };
  order_status: {
    labels: string[];
    data: number[];
  };
  orders_value_overview: {
    labels: string[];
    data: number[];
  };
  revenue_overview: {
    labels: string[];
    data: number[];
  };
}
