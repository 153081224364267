import { Box, Container, Unstable_Grid2 as Grid } from "@mui/material";

import { OverviewLatestOrders } from "../sections/overview/overview-latest-orders";
import { usePackages } from "../hooks/use-orders";
import React, { useState } from "react";
import { DashboardOverviewData } from "../types";
import client from "../api";
import { useAuth } from "../hooks/auth";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PeopleIcon from "@mui/icons-material/People";
import MotorcycleIcon from "@mui/icons-material/ElectricBike";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { Overview } from "../sections/overview/overview-card";
import { FinancialOverview } from "../sections/overview/financial-overview";
import { OrderStatusOverview } from "../sections/overview/overview-order-status";
import { useNavigate } from "react-router-dom";
import { paths } from "../components/layout/dashboard/config";
import { useToast } from "../hooks/use-toast";

const colors = {
  success: "success.main",
  error: "error.main",
  primary: "primary.main",
  warning: "warning.main",
};
const Home = () => {
  const { data: orders } = usePackages(0, 10, "");
  const { isAuthenticated } = useAuth();
  const [overview, setOverview] = useState<DashboardOverviewData | null>();
  const navigate = useNavigate()
  const { showToast } = useToast();
  
  React.useEffect(() => {
    if (isAuthenticated) {
      client.admin.overview().then((data) => setOverview(data)).catch(error => {
        showToast({
          message: error.message,
          type: "error",
      });
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}

      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid xs={12} sm={6} lg={4}>

              <Overview
                sx={{ height: "100%" }}
                value={overview?.users.total}
                thisMonthValue={overview?.users.this_month}
                color={colors.error}
                icon={<PeopleIcon />}
                title="Users"
                onClick={() => navigate(paths.users)}
              />

            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <Overview
                title="Riders"
                sx={{ height: "100%" }}
                value={overview?.riders.total}
                thisMonthValue={overview?.riders.this_month}
                color={colors.primary}
                icon={<MotorcycleIcon />}
                onClick={() => navigate(paths.riders)}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <Overview
                title="Orders"
                sx={{ height: "100%" }}
                value={overview?.orders.total}
                thisMonthValue={overview?.orders.this_month}
                color={colors.error}
                icon={<ShoppingBasketIcon />}
                onClick={() => navigate(paths.orders)}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <Overview
                title="Completed Orders Value"
                sx={{ height: "100%" }}
                value={overview?.order_total_amount.total}
                thisMonthValue={overview?.order_total_amount.this_month}
                color={colors.warning}
                icon={<AttachMoneyIcon />}
                onClick={() => navigate(paths.orders)}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <Overview
                title="Revenue"
                sx={{ height: "100%" }}
                value={overview?.revenue.total}
                thisMonthValue={overview?.revenue.this_month}
                color={colors.warning}
                icon={<AttachMoneyIcon />}
                onClick={() => navigate(paths.orders)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            <Grid xs={12} lg={8}>
              <FinancialOverview
                dates={overview?.revenue_overview.labels ?? []}
                ordersTotalValueData={
                  overview?.orders_value_overview.data ?? []
                }
                profitOverviewsData={overview?.revenue_overview.data ?? []}
                sx={{ height: "100%" }}

              />
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <OrderStatusOverview
                chartSeries={overview?.order_status.data ?? []}
                labels={overview?.order_status.labels ?? []}
                sx={{ height: "100%" }}
              />
            </Grid>

            <Grid xs={12} md={12} lg={12}>
              <OverviewLatestOrders
                orders={orders?.data??[]}
                sx={{ height: "100%" }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Home;
