import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Courier, CourierPackageDetail, ErrorResponse } from "../types";
import client from "../api";
import { useToast } from "./use-toast";

export const useCouriers = () => {
  return useQuery<Courier[], ErrorResponse, Courier[]>({
    queryFn: (context) => client.couriers.all(),
    queryKey: ["couriers"],
  });
};

export const useCourier = (id: number) => {
  const queryClient = useQueryClient();

  return useQuery<Courier, ErrorResponse, Courier>({
    queryKey: ["couriers", id],
    queryFn: async () => {
      // Check if the courier exists in the cache
      const cachedData = queryClient.getQueryData<Courier[]>(["couriers"]);
      if (cachedData) {
        const courier = cachedData.find((c) => c.id === +id);

        if (courier) return courier;
      }

      // Fetch the courier from the API if not found in the cache
      return client.couriers
        .all()
        .then((couriers) => couriers.find((c) => c.id === +id)) as any;
    },
  });
};

export const useAddCourier = () => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  return useMutation<
    Courier,
    ErrorResponse,
    {
      data: Partial<Courier>;
    }
  >({
    mutationFn: ({ data }) => client.couriers.create(data),
    onError: (error) => {
      showToast({
        message: error.message,
        type: "error",
      });
    },
    onMutate: () => {
      showToast({
        message: "Creating new courier please wait...",
        type: "success",
        dismissTime: 5000,
      });
    },
    onSuccess: (data) => {
      showToast({
        message: "Courier Created Successfully",
        type: "success",
      });
      queryClient.invalidateQueries(["couriers"]);
    },
  });
};

export const useUpdateCourier = () => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  return useMutation<
    Courier,
    ErrorResponse,
    {
      id: number;
      data: Partial<Courier>;
    }
  >({
    mutationFn: ({ id, data }) => client.couriers.update(data, id),
    onError: (error) => {
      showToast({
        message: error.message,
        type: "error",
      });
    },
    onMutate: () => {
      showToast({
        message: "Updating please wait...",
        type: "success",
        dismissTime: 5000,
      });
    },
    onSuccess: (data) => {
      showToast({
        message: "Courier Updated Successfully",
        type: "success",
      });
      queryClient.invalidateQueries(["couriers"]);
      queryClient.setQueryData(["couriers", data.id], data);
      queryClient.invalidateQueries(["couriers", data.id]);
      queryClient.refetchQueries(["couriers", data.id]);
    },
  });
};

export const useDeleteCourierDetail = (courierId: number) => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  return useMutation<
    CourierPackageDetail,
    ErrorResponse,
    {
      id: number;
    }
  >({
    mutationFn: ({ id }) => client.couriers.deleteCategory(id),
    onError: (error) => {
      showToast({
        message: error.message,
        type: "error",
      });
    },
    onMutate: () => {
      showToast({
        message: "Deleting Courier category",
        type: "success",
        dismissTime: 5000,
      });
    },
    onSuccess: (data) => {
      showToast({
        message: "Courier Category Deleted Successfully",
        type: "success",
      });
      queryClient.invalidateQueries(["couriers"]);
      const cachedCourier = queryClient.getQueryData<Courier>([
        "couriers",
        +courierId,
      ]);

      if (cachedCourier) {
        const updatedDetails = cachedCourier.details.filter(
          (detail) => detail.id !== data.id
        );
        queryClient.setQueryData(["couriers", +courierId], {
          ...cachedCourier,
          details: updatedDetails,
        });
      }
      queryClient.invalidateQueries(["couriers", +courierId]);
      queryClient.refetchQueries(["couriers", +courierId]);

    },
  });
};
