import BarChartIcon from "@mui/icons-material/BarChart";
import SettingsIcon from "@mui/icons-material/Settings";
import PeopleIcon from "@mui/icons-material/People";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FeedbackIcon from "@mui/icons-material/Feedback";
import PaymentIcon from "@mui/icons-material/Payment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { SvgIcon } from "@mui/material";

export const paths = {
  overview: "/",
  users: "/users/",
  account: "/account/",
  settings: "/settings/",
  login: "/auth/login/",
  register: "/auth/register/",
  error: "/404/",
  orders: "/orders/",
  riders: "/riders/",
  couriers: "/couriers/",
  feedback: "/feedback/",
  transactions: "/transactions/",
  riderEarning: "/riders/:id/earning",
};

export const items = [
  {
    title: "Overview",
    path: paths.overview,
    icon: (
      <SvgIcon>
        <BarChartIcon fontSize="small" />
      </SvgIcon>
    ),
  },
  {
    title: "Users",
    path: paths.users,
    icon: (
      <SvgIcon>
        <PeopleIcon fontSize="small" />
      </SvgIcon>
    ),
  },
  {
    title: "Riders",
    path: paths.riders,
    icon: (
      <SvgIcon>
        <PeopleIcon fontSize="small" />
      </SvgIcon>
    ),
  },
  {
    title: "Orders",
    path: paths.orders,
    icon: (
      <SvgIcon>
        <ShoppingCartIcon fontSize="small" />
      </SvgIcon>
    ),
  },
  {
    title: "Feedback",
    path: paths.feedback,
    icon: (
      <SvgIcon>
        <FeedbackIcon fontSize="small" />
      </SvgIcon>
    ),
  },
  {
    title: "Transactions",
    path: paths.transactions,
    icon: (
      <SvgIcon>
        <PaymentIcon fontSize="small" />
      </SvgIcon>
    ),
  },
  {
    title: "Couriers",
    path: paths.couriers,
    icon: (
      <SvgIcon>
        <LocalShippingIcon fontSize="small" />
      </SvgIcon>
    ),
  },
  // {
  //   title: "Account",
  //   path: paths.account,
  //   icon: (
  //     <SvgIcon>
  //       <PersonIcon fontSize="small" />
  //     </SvgIcon>
  //   ),
  // },
  {
    title: "Settings",
    path: paths.settings,
    icon: (
      <SvgIcon>
        <SettingsIcon fontSize="small" />
      </SvgIcon>
    ),
  },
];
