import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ErrorResponse, Package, Paginator } from "../types";
import client from "../api";
import { useToast } from "./use-toast";

export const usePackages = (
  page?: number,
  rowsPerPage?: number,
  searchQuery?: string,
  filter?: string
) => {
  return useQuery<Paginator<Package>, ErrorResponse, Paginator<Package>>({
    queryFn: (_) => client.package.all(page, rowsPerPage, searchQuery, filter),
    queryKey: ["packages", page, rowsPerPage, searchQuery, filter],
    initialData: { page: 0, data: [], pages: 0, count:0 },
  });
};

export const usePackage = (id: number) => {
  return useQuery<Package, ErrorResponse, Package>({
    queryFn: () => client.package.detail(id),
    queryKey: ["packages/", id],
  });
};

export const useUpdatePackage = (id: number) => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  return useMutation<
    Package,
    ErrorResponse,
    {
      id: number;
      data: Partial<Package>;
    }
  >({
    mutationFn: ({ id, data }) => client.package.update(data, id),
    onError: (error) => {
      showToast({
        message: error.message,
        type: "error",
      });
    },
    onMutate: () => {
      showToast({
        message: "Updating please wait...",
        type: "success",
        dismissTime: 5000,
      });
    },
    onSuccess: (data) => {
      showToast({
        message: "Package Updated Successfully",
        type: "success",
      });
      queryClient.invalidateQueries({
        predicate: (query) => `${query.queryKey[0]}`.includes("packages"),
      });
    },
  });
};

export const useAssignPackage = (id: number) => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  return useMutation<
    Package,
    ErrorResponse,
    {
      riderId: number
    }
  >({
    mutationFn: ({ riderId }) => client.package.assign(id, riderId),
    onError: (error) => {
      showToast({
        message: error.message,
        type: "error",
      });
    },
    onMutate: () => {
      showToast({
        message: "Updating please wait...",
        type: "success",
        dismissTime: 5000,
      });
    },
    onSuccess: (data) => {
      showToast({
        message: "Package Updated Successfully",
        type: "success",
      });
      queryClient.invalidateQueries({
        predicate: (query) => `${query.queryKey[0]}`.includes("packages"),
      });
    },
  });
};
