import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { formatNumber } from "../../utils";

interface OverviewProps {
  color: string;
  title: string;
  value?: number;
  thisMonthValue?: number;
  icon: React.ReactElement;
  sx?: object;
  onClick?: any;
}

export const Overview: React.FC<OverviewProps> = (props) => {
  const { color, title, value, thisMonthValue, icon: Icon, sx, onClick } = props;

  return (
    <Card sx={{...(sx?sx:{}), cursor:"pointer"}} onClick={onClick}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline" sx={{ textTransform: "upper-case" }}>
              {title}
            </Typography>
            <Typography variant="h4">
              {formatNumber(value)}
            </Typography>
          </Stack>
          <Avatar sx={{ backgroundColor: color, height: 56, width: 56 }}>
            <SvgIcon>{Icon}</SvgIcon>
          </Avatar>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={2} sx={{ mt: 2 }}>
          <Typography color="text.secondary" variant="caption">
            {formatNumber(thisMonthValue)} This month
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
