import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ErrorResponse, Feedback, Paginator, Rider, User } from "../types";
import client from "../api";
import { useToast } from "./use-toast";

export const useUsers = (
  page?: number,
  rowsPerPage?: number,
  searchQuery?: string
) => {
  return useQuery<Paginator<User>, ErrorResponse, Paginator<User>>({
    queryFn: (context) => client.users.users(page, rowsPerPage, searchQuery),
    queryKey: ["users", page, rowsPerPage, searchQuery],
  });
};

export const useUpdateCustomer = () => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  return useMutation<
    User,
    ErrorResponse,
    {
      id: number;
      page?: number;
      rowsPerPage?: number;
      searchQuery?: string;
      data: Partial<User>;
    }
  >({
    mutationFn: ({ id, data }) => client.users.userUpdate(id, data),
    onError: (error) => {
      showToast({
        message: error.message,
        type: "error",
      });
    },
    onMutate: () => {
      showToast({
        message: "Updating please wait...",
        type: "success",
        dismissTime: 5000,
      });
    },
    onSuccess: (data, { page, rowsPerPage, searchQuery }) => {
      showToast({
        message: "User Updated Successfully",
        type: "success",
      });
      queryClient.setQueryData(
        ["users", page, rowsPerPage, searchQuery],
        (oldData?: Paginator<User> | null) =>
          oldData
            ? {
                ...oldData,
                data: oldData.data.map((user) => {
                  if (user.id === data.id) {
                    return data;
                  }
                  return user;
                }),
              }
            : oldData
      );
    },
  });
};

export const useRiders = (searchQuery?: string, filter?:string) => {
  return useQuery<Rider[], ErrorResponse>({
    queryFn: () => client.users.riders(searchQuery, filter),
    queryKey: ["riders", searchQuery,filter],
    initialData: [],
  });
};

export const useUpdateRiderOrAddVerificationDocument = () => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  return useMutation<
    Rider,
    ErrorResponse,
    {
      id: number;
 
      searchQuery?: any;
      data: Partial<Rider|string>;
    }
  >({
    mutationFn: ({ id, data }) => {
      if(typeof data == "string"){
          return client.users.riderAddVerification(id, data)  
      }
      return client.users.riderUpdate(id, data)
    },
    onError: (error) => {
      showToast({
        message: error.message,
        type: "error",
      });
    },
    onMutate: () => {
      showToast({
        message: "Updating please wait...",
        type: "success",
        dismissTime: 5000,
      });
    },
    onSuccess: (data, { searchQuery }) => {
      showToast({
        message: "User Updated Successfully",
        type: "success",
      });
      queryClient.setQueryData(["riders/", data.id],()=>data)
      queryClient.setQueryData(
        ["riders", searchQuery],
        (oldData?: Rider[] | null) =>
          oldData
            ? oldData.map((user) => {
                if (user.id === data.id) {
                  return data;
                }
                return user;
              })
            : oldData
      );
    },
  });
};

export const useFeedback = () => {
  return useQuery<Feedback[], ErrorResponse>({
    queryFn: () => client.users.feedback(),
    queryKey: ["feedback"],
    initialData: [],
  });
};


export const useRider = (id: number) => {
  return useQuery<Rider, ErrorResponse, Rider>({
    queryFn: () => {
      return client.users.riderDetails(id)
    },
    queryKey: ["riders/", +id],
  });
};