import React, { useState } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  InputLabel,
  FormControl,
  Select,
  Button,
  MenuItem,
} from "@mui/material";
import { DeliveryTimeline, Package, PackageStatus } from "../../types";
import { format } from "date-fns";
import { SeverityPill } from "../../components/severity-pill";
import { statusMap } from "../overview/overview-latest-orders";
import { useUpdatePackage } from "../../hooks/use-orders";

export const DeliveryTimelines = ({
  deliveryTimelines,
}: {
  deliveryTimelines: DeliveryTimeline[];
}) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Typography variant="h6" component="div">
          Delivery Timelines
        </Typography>
        <List>
          {deliveryTimelines.map((timeline, i) => (
            <ListItem key={i}>
              <ListItemText
                primary={timeline.message}
                secondary={format(
                  Date.parse(timeline.created_at),
                  "dd/MM/yyyy HH:mm"
                )}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export const OrderDetailCard = ({
  order,
  page,
  rowsPerPage,
  searchQuery
}: { order: Package; page?: number; rowsPerPage?: number; searchQuery?: string }) => {
  const [selectedStatus, setSelectedStatus] = useState<PackageStatus>(order.status);

  const handleStatusChange = (event: any) => {
    setSelectedStatus(event.target.value as any);
  };
  const { mutate, isLoading } = useUpdatePackage(order.id)
  const handleSubmit = () => {
    if (selectedStatus === order.status) {
      return
    }
    mutate(
      {
        id: order.id, data: {
          status: selectedStatus,

        }
      }
    )
  };
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div">
          Order Details
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Package Number"
              secondary={order.package_number}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Created On" secondary={format(Date.parse(order.created_at), "dd/MM/yyyy HH:mm")} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Status"
              secondary={
                <SeverityPill color={statusMap[order.status] as any}>
                  {order.status}
                </SeverityPill>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Pickup Address"
              secondary={`${order.pickup_address.full_name}, ${order.pickup_address.street_address},  ${order.pickup_address.phone_number}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Delivery Address"
              secondary={`${order.delivery_address.full_name}, ${order.delivery_address.street_address},  ${order.delivery_address.phone_number}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Customer Feedback" secondary={order.feedback??"N/A"} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Cost" secondary={order.cost} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Rider Earning" secondary={order.rider_earning} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Commission" secondary={order.commission} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Courier" secondary={order.category.courier.type + "-" + order.category.name} />
          </ListItem>
        </List>


        <Typography variant="h6" component="div">
          Update Delivery Status
        </Typography>
        <div style={{ display: "flex", alignItems: "center", marginTop: "16px" }}>
          <FormControl>
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={selectedStatus}
              onChange={handleStatusChange}
            >
              <MenuItem value={order.status}>
                Select Status
              </MenuItem>
              <MenuItem value="ADMIN_CONFIRMED">
                DELIVERED
              </MenuItem>
              <MenuItem value={"ADMIN_CANCELLED"}>
                CANCELLED
              </MenuItem>

            </Select>
          </FormControl>
          <Button variant="contained" onClick={handleSubmit} style={{ marginLeft: "8px" }} disabled={isLoading} >
            Update Status
          </Button>
        </div>
      </CardContent>
    </Card >
  );
};


