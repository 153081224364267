import {
  format,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
} from "date-fns";
export const joinPath = (...args: string[]) => {
  
  return (
    args
      // Remove leading and trailing slashes
      .map((arg) => arg.replace(/^\/|\/$/g, ""))
      // Remove empty segments
      .filter((arg) => arg.length > 0)
      .join("/")
  );
};

export const formatNumber = (n?: number) => {
  if (!n && n !== 0) {
    return "";
  }

  return n.toLocaleString();
};

export const formatTimeAgo = (createdAt: string) => {
  const now = new Date();
  const createdAtDate = new Date(createdAt);

  const minutesDiff = differenceInMinutes(now, createdAtDate);
  const hoursDiff = differenceInHours(now, createdAtDate);
  const daysDiff = differenceInDays(now, createdAtDate);

  if (minutesDiff < 1) {
    return "just now";
  } else if (minutesDiff < 60) {
    return `${minutesDiff} minute${minutesDiff !== 1 ? "s" : ""} ago`;
  } else if (hoursDiff < 24) {
    return `${hoursDiff} hour${hoursDiff !== 1 ? "s" : ""} ago`;
  } else if (daysDiff < 2) {
    return "yesterday";
  } else {
    return format(createdAtDate, "dd/MM/yyyy HH:mm");
  }
};
