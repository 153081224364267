import React from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import { Transaction } from "../../types";
import { format } from "date-fns";
import { SeverityPill } from "../../components/severity-pill";
import { paymentStatus } from "../overview/overview-latest-orders";
import { useNavigate } from "react-router-dom";

const OrderPaymentDetails = ({ payment }: { payment?: Transaction }) => {
  const navigate = useNavigate()
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Typography variant="h6" component="div">
          Payment Details
        </Typography>
        <List>
          <ListItem sx={{ my: 0 }}>
            <ListItemText
              primary="Payment Mode"
              secondary={payment?.payment_mode}
            />
          </ListItem>
          <ListItem sx={{ my: 0 }}>
            <ListItemText
              primary="Account Debited"
              secondary={payment?.account_debited}
            />
          </ListItem>
          <ListItem sx={{ my: 0 }}>
            <ListItemText
              primary="Date  Created"
              secondary={
                payment?.created_at
                  ? format(Date.parse(payment?.created_at), "dd/MM/yyyy HH:mm")
                  : ""
              }
            />
          </ListItem>
          <ListItem sx={{ my: 0 }}>
            <ListItemText
              primary="Status"
              secondary={
                <SeverityPill
                  color={paymentStatus[payment?.status ?? "PENDING"] as any}
                >
                  {payment?.status}
                </SeverityPill>
              }
            />
          </ListItem>
          <ListItem sx={{ my: 0 }}>
            <ListItemText primary="Amount" secondary={payment?.amount} />
          </ListItem>
          {payment?.additional_details && (
            <ListItem sx={{ my: 0 }}>
              <ListItemText
                primary="Additional Details"
                secondary={payment?.additional_details}
              />
            </ListItem>
          )}
        </List>
        <Button
          variant="text"
          onClick={() => {
            navigate(`/transactions/${payment?.id}`)
          }}
        >
          View
        </Button>
      </CardContent>
    </Card>
  );
};

export default OrderPaymentDetails;
