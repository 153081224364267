import { useQuery } from "@tanstack/react-query";
import client from "../api";
import { ErrorResponse, Paginator, Transaction } from "../types";

export const useTransactions = (
  page?: number,
  rowsPerPage?: number,
  searchQuery?: string
) => {
  return useQuery<
    Paginator<Transaction>,
    ErrorResponse,
    Paginator<Transaction>
  >({
    queryFn: (props:any) => client.transactions.all(page, rowsPerPage, searchQuery),
    queryKey: ["transactions", page, rowsPerPage, searchQuery],
    initialData: { page: 0, data: [], pages: 0, count:0 },
  });
};
