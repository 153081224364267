import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  SvgIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Checkbox,
  MenuItem,
} from "@mui/material";
import { Scrollbar } from "../components/scroll-bar";
import { useAddCourier, useCouriers } from "../hooks/use-couriers";
import { Courier, Courier as CourierInterface, CourierType } from "../types";
import { joinPath } from "../utils";
import { BASE_URL } from "../api/axios";
import { useToast } from "../hooks/use-toast";
import { useNavigate } from "react-router-dom";
import { paths } from "../components/layout/dashboard/config";
import PlusIcon from "@mui/icons-material/Add";

const CourierPage = () => {
  const { data: couriers, error } = useCouriers();
  const [dialogOpen, setOpenDialog] = useState(false);
  const { mutate: create, data: createSuccess } = useAddCourier();

  const navigate = useNavigate();

  const handleViewCourier = (courier: CourierInterface) => {
    navigate(paths.couriers + courier.id);
  };
  const courierAdd = (courier: Partial<Courier>) => {
    create({
      data: courier,
    });
  };

  const { showToast } = useToast();
  React.useEffect(() => {
    if (error) {
      showToast({
        message: error.message,
        type: "error",
      });
    }
  }, [error, showToast]);

  React.useEffect(() => {
    if (createSuccess) {
      setOpenDialog(false);
    }
  }, [createSuccess]);
  return (
    <>
      <CourierAddDialog
        open={dialogOpen}
        handleCreate={courierAdd}
        setOpen={setOpenDialog}
      />
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Courier</Typography>
              </Stack>
              <div>
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  onClick={() => setOpenDialog(true)}
                >
                  Add
                </Button>
              </div>
            </Stack>
            <Card>
              <Scrollbar>
                <Box sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Icon</TableCell>
                        <TableCell>Courier Type</TableCell>
                        <TableCell>Fee Percentage</TableCell>
                        <TableCell>Base Distance (Km) </TableCell>
                        <TableCell>Max Distance(Km)</TableCell>
                        <TableCell>Available</TableCell>
                        <TableCell padding="checkbox">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {couriers &&
                        couriers.map((courier) => {
                          const isSelected = false;

                          return (
                            <TableRow
                              hover
                              key={courier.id}
                              selected={isSelected}
                            >
                              <TableCell>
                                <Stack
                                  alignItems="center"
                                  direction="row"
                                  spacing={2}
                                >
                                  <Avatar
                                    src={courier.icon}
                                  >
                                    {courier.type.charAt(0)}
                                  </Avatar>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {courier.type}
                                </Typography>
                              </TableCell>
                              <TableCell>{courier.fee_percentage}</TableCell>
                              <TableCell>{courier.base_distance}</TableCell>
                              <TableCell>{courier.max_distance}</TableCell>
                              <TableCell>
                                {courier.available ? "Yes" : "No"}
                              </TableCell>

                              <TableCell padding="checkbox">
                                <Button
                                  variant="outlined"
                                  onClick={() => handleViewCourier(courier)}
                                >
                                  View
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Box>
              </Scrollbar>
            </Card>
          </Stack>
        </Container>
      </Box>
    </>
  );
};
interface CourierAddDialogProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  handleCreate: (newCourier: any) => void;
}

function CourierAddDialog({
  open,
  setOpen,
  handleCreate,
}: CourierAddDialogProps) {
  const [newCourier, setNewCourier] = useState({
    type: "",
    fee_percentage: "",
    base_distance: "",
    max_distance: "",
    available: false,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setNewCourier({
      ...newCourier,
      [name]: newValue,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleCreate(newCourier);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Add New Courier</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  label="Courier Type"
                  name="type"
                  value={newCourier.type}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  helperText="Type of courier"
                >
                  {Object.values(CourierType).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Fee Percentage (%)"
                  name="fee_percentage"
                  value={newCourier.fee_percentage}
                  onChange={handleInputChange}
                  type="number"
                  fullWidth
                  required
                  helperText="Fee percentage charged for each courier per delivery"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Base Distance (km)"
                  name="base_distance"
                  value={newCourier.base_distance}
                  onChange={handleInputChange}
                  type="number"
                  fullWidth
                  required
                  helperText="Maximum distance that is considered to be under base price"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Maximum Distance (km)"
                  name="max_distance"
                  value={newCourier.max_distance}
                  onChange={handleInputChange}
                  type="number"
                  fullWidth
                  required
                  helperText="Fee percentage charged for each courier per delivery"
                />
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  name="available"
                  checked={newCourier.available}
                  onChange={handleInputChange}
                />
                Available
              </Grid>
            </Grid>
          </Stack>
          <Button variant="contained" color="primary" type="submit">  
            Add Courier
          </Button>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" type="button">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default CourierPage;
