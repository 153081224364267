import { Box, Container, Typography, Card, IconButton, Collapse, List, ListItem, ListItemText, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { RiderEarning, RiderPaymentStatus } from '../types';
import client from '../api';
import { useParams } from 'react-router-dom';
import { useToast } from "../hooks/use-toast";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatTimeAgo } from '../utils';
import { SeverityPill } from '../components/severity-pill';

const RiderEarningHistory = () => {
    const [earnings, setEarnings] = useState<RiderEarning[]>([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const { showToast } = useToast()
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await client.transactions.riderEarning(
                    id as any
                )
                setEarnings(data);
            } catch (error: any) {
                showToast({
                    message: error.message,
                    type: "error",
                });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return (
        <>

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8
                }}
            >
                <Container maxWidth="lg">
                    <Typography variant="h4" mb={4}>
                        Rider Earnings
                    </Typography>
                    <Card sx={{ p: 2 }}>
                        {loading ? (
                            <Typography>Loading...</Typography>
                        ) : (
                            earnings.map((earning, i) => (
                                <>
                                    <ExpandableCard key={i} earning={earning} isExpanded={i === 0} />
                                    <Divider />
                                </>
                            ))
                        )}
                    </Card>
                </Container>
            </Box>
        </>
    );
}

const ExpandableCard = ({ isExpanded, earning }: {
    isExpanded: boolean, earning: RiderEarning
}) => {
    const [expanded, setExpanded] = useState(isExpanded);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const getColor = (status: any) => {
        if (status === RiderPaymentStatus.FAILED || status === RiderPaymentStatus.REJECTED) {
            return "error"
        }
        if (status === RiderPaymentStatus.COMPLETED) {
            return "success"
        }
        if (status) {
            return "warning"
        }
        return "info"
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">
                    <SeverityPill color={getColor(earning.approval_status) as any}>Ksh {earning.amount} </SeverityPill>
                    {
                        earning.id != null ?
                            <>
                                Withdraw Request  {formatTimeAgo(earning.created_at!)}   <SeverityPill
                                    color={getColor(earning.approval_status)}
                                >
                                    {earning.approval_status}
                                </SeverityPill>
                            </> : <> Current Balance </>
                    }</Typography>
                <IconButton onClick={handleExpandClick}>
                    <ExpandMoreIcon />
                </IconButton>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <List component="ol">
                    {earning.earning_overview.map((overview, index) => (
                        <ListItem component={"li"} key={index} sx={{}}>
                            <ListItemText
                                primary={overview.description}
                                secondary={`Ksh ${overview.effective_amount}`}
                            />
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </>
    );
};


export default RiderEarningHistory;
