import {
  Box,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { Scrollbar } from "../components/scroll-bar";
import { useFeedback } from "../hooks/use-users";
import { format } from "date-fns";
import { useToast } from "../hooks/use-toast";
import React from "react";

const Feedback = () => {
  const { data, isLoading, error } = useFeedback();
  const { showToast } = useToast();
  React.useEffect(() => {
    if (error) {
      showToast({
        message: error.message,
        type: "error",
      });
    }
  }, [error, showToast]);
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Feedback</Typography>
              </Stack>
            </Stack>
            <Card>
              <Scrollbar>
                <Box sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>User</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Subject</TableCell>
                        <TableCell style={{ minWidth: "200px" }}>
                          Message
                        </TableCell>
                        <TableCell>Created At</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading ? (
                        <p></p>
                      ) : (
                        data.map((feedback) => {
                          const isSelected = false;
                          const createdAt = format(
                            Date.parse(feedback.user.created_at),
                            "dd/MM/yyyy HH:mm"
                          );

                          return (
                            <TableRow
                              hover
                              key={feedback.id}
                              selected={isSelected}
                            >
                              <TableCell>
                                <Stack
                                  alignItems="center"
                                  direction="row"
                                  spacing={2}
                                >
                                  <Typography variant="subtitle2">
                                    {feedback.user.first_name}{" "}
                                    {feedback.user.last_name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>{feedback.user.email}</TableCell>
                              <TableCell>
                                {feedback.user.phone_number}
                              </TableCell>
                              <TableCell>{feedback.subject}</TableCell>
                              <TableCell>{feedback.message}</TableCell>
                              <TableCell>{createdAt}</TableCell>
                            </TableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Scrollbar>
            </Card>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Feedback;
