import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useAssignPackage, usePackage } from "../hooks/use-orders";
import { useParams } from "react-router-dom";
import {
  OrderDetailCard,
  DeliveryTimelines,
} from "../sections/orders/order-delivery-details";
import OrderPaymentDetails from "../sections/orders/order-payment-details";
import OrderUserDetail, { OrderRiderDetail } from "../sections/orders/order-user-detail";
import { useToast } from "../hooks/use-toast";
import React from "react";
import { PackageStatus } from "../types";

const OrdersDetailsPage = () => {
  let { id } = useParams();
  const { data: order, error } = usePackage(id as any);
  const { error: updateError, mutate: assign } = useAssignPackage(id as any)
  const { showToast } = useToast();

  React.useEffect(() => {
    if (error && updateError) {
      showToast({
        message: (error || updateError).message,
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, updateError]);

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}>
              <Typography variant="h4">Order Detail</Typography>
            </Stack>
          </Stack>
        </Stack>

        {!order ? (
          <>loading...</>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={8}>
              <OrderDetailCard order={order} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OrderRiderDetail user={order.rider} canAssign={
                (
                  order.status === PackageStatus.PENDING ||
                  order.status === PackageStatus.RIDER_CANCELLED ||
                  order.status === PackageStatus.ADMIN_CANCELLED
                ) &&
                order.payment?.status === "COMPLETED"
              }
                onAssign={(riderId) => { assign({ riderId }) }}
                courierType={order.category.courier.type}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DeliveryTimelines deliveryTimelines={order.delivery_timelines} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OrderPaymentDetails payment={order.payment} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OrderUserDetail user={order.user} />
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default OrdersDetailsPage;
