import {
  AccessToken,
  MessageResponse,
  Courier,
  CourierPackageDetail,
  Feedback,
  Package,
  Rider,
  User,
  Paginator,
  Transaction,
  DashboardOverviewData,
  RiderEarning,
} from "../types";
import { Authorization, getToken } from "../utils/local-storage";
import { BASE_URL, Http } from "./axios";
const APIEndpoints = {
  login: "/admin/login/",
  users: "/admin/users/",
  riders: "/admin/riders/",
  feedback: "/admin/feedback/",
  courier: "/admin/courier/",
  courierCreate: "/admin/courier/",
  packageCategory: "/admin/courier/detail-item/",
  packages: "/admin/packages/",
  transactions: "/admin/transactions/",
  overview: "/admin/overview/",
  profile: "/customer/profile/",
};

class Client {
  users = {
    login: (phone_number: string, password: string): Promise< MessageResponse > =>
      Http.post(APIEndpoints.login, { phone_number, password }),
    requestOTP: (phone_number: string): Promise< MessageResponse > =>
      Http.post("/otp/request/", { receiver: phone_number, channel:"SMS", purpose:"ADMIN_LOGIN" }),
    verifyOTP: (phone_number: string, otp:string): Promise< AccessToken > =>
      Http.post("/admin/login/verify-otp/", { phone_number, purpose:"ADMIN_LOGIN", otp_code:otp }),
      
    users: (
      page?: number,
      page_size?: number,
      q?: string
    ): Promise<Paginator<User>> =>
      Http.get(APIEndpoints.users, { page, page_size, q }),
    userUpdate: (id: number, data: Partial<User>): Promise<User> =>
      Http.post(APIEndpoints.users + id, data),

    riders: (q?: string, courier_type?: string): Promise<Rider[]> =>
      Http.get(APIEndpoints.riders, { q, courier_type }),
    riderDetails: (id:number): Promise<Rider> =>
      Http.get(APIEndpoints.riders+id),
    riderUpdate: (id: number, data: Partial<Rider>): Promise<Rider> =>
      Http.post(APIEndpoints.riders + id, data),
    riderAddVerification: (id: number, document_name:string): Promise<Rider> =>
      Http.post(APIEndpoints.riders + `${id}/verification/document-request/${document_name}/`, ),  
    feedback: (): Promise<Feedback[]> => Http.get(APIEndpoints.feedback),
    profile: (): Promise<User> => Http.get(APIEndpoints.profile),
  };
  couriers = {
    all: (): Promise<Courier[]> => Http.get(APIEndpoints.courier),
    create: (data: Partial<Courier>): Promise<Courier> =>
      Http.post(APIEndpoints.courierCreate, data),
    update: (data: Partial<Courier>, id: number): Promise<Courier> =>
      Http.put(`${APIEndpoints.courierCreate}${id}`, data),
    delete: (id: number): Promise<Courier> =>
      Http.delete(`${APIEndpoints.courierCreate}${id}`),
    deleteCategory: (id: number): Promise<CourierPackageDetail> =>
      Http.delete(`${APIEndpoints.packageCategory}${id}`),
  };
  package = {
    all: (
      page?: number,
      page_size?: number,
      q?: string,
      status?: string
    ): Promise<Paginator<Package>> =>
      Http.get(APIEndpoints.packages, { page, page_size, q, status }),

    update: (data: Partial<Package>, id: number): Promise<Package> =>
      Http.post(`${APIEndpoints.packages}${id}`, data),
    detail: (id: number): Promise<Package> =>
      Http.get(`${APIEndpoints.packages}${id}`),
    assign: (id: number, riderId: number): Promise<Package> =>
      Http.post(`${APIEndpoints.packages}${id}/${riderId}/assign/`),
  };
  transactions = {
    all: (
      page?: number,
      page_size?: number,
      q?: string
    ): Promise<Paginator<Transaction>> =>
      Http.get(APIEndpoints.transactions, { page, page_size, q }),
    riderEarning: (riderId: number | string): Promise<Array<RiderEarning>> =>
      Http.get(`/admin/riders/${riderId}/earning/`),
    transactionDetails: (id: number | string): Promise<Transaction> =>
      Http.get(`/admin/transactions/${id}`),
    updateTransaction: (
      id: number | string,
      data: Partial<Transaction>
    ): Promise<Transaction> => Http.post(`/admin/transactions/${id}`, data),
    reverseTransaction: (id: number | string): Promise<Transaction> =>
      Http.post(`/admin/transactions/${id}/reverse/`, {}),
  };
  admin = {
    overview: (): Promise<DashboardOverviewData> =>
      Http.get(APIEndpoints.overview),
  };
  downloadAll = async (folder_path: string) => {
    try {
      const authToken = getToken()

      // Make a POST request to the server to initiate the download
      const response = await fetch(`${BASE_URL}/admin/download/all/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ folder_path }),
      });

      // Check if the request was successful (status code 200-299)
      if (response.ok) {
        // Get the download URL from the response
        const contentDisposition = response.headers.get("Content-Disposition");
        const match =
          contentDisposition && contentDisposition.match(/filename="(.+)"/);
        const filename = match
          ? match[1]
          : `${folder_path.split("/").pop()}.zip`;

        // Get the blob data from the response
        const blobData = await response.blob();

        // Create a Blob URL from the blob data
        const blobUrl = URL.createObjectURL(blobData);

        // Create an anchor tag
        const downloadLink = document.createElement("a");
        downloadLink.style.display = "none";
        downloadLink.href = blobUrl;
        downloadLink.download = filename;

        // Append the link to the document body
        document.body.appendChild(downloadLink);

        // Trigger a click event on the link to start the download
        downloadLink.click();

        // Remove the link from the document body
        document.body.removeChild(downloadLink);
      } else {
        // Handle error responses here
        console.error("Failed to initiate download:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      throw error
    }
  };
  download = async (path: string) => {
    try {
      const authToken = getToken()

      // Make a POST request to the server to initiate the download
      const response = await fetch(`${BASE_URL}/admin/download/?path=${path}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      // Check if the request was successful (status code 200-299)
      if (response.ok) {
        // Get the download URL from the response
        // const downloadUrl = await response.text();
        const contentDisposition = response.headers.get("Content-Disposition");
        const match =
          contentDisposition && contentDisposition.match(/filename="(.+)"/);
        const filename = match ? match[1] : `${path.split("/").pop()}`;

        // Create an anchor tag
        // Get the blob data from the response
        const blobData = await response.blob();

        // Create a Blob URL from the blob data
        const blobUrl = URL.createObjectURL(blobData);

        // Create an anchor tag
        const downloadLink = document.createElement("a");
        downloadLink.style.display = "none";
        downloadLink.href = blobUrl;
        downloadLink.download = filename;

        // Append the link to the document body
        document.body.appendChild(downloadLink);

        // Trigger a click event on the link to start the download
        downloadLink.click();

        // Remove the link from the document body
        document.body.removeChild(downloadLink);
      } else {
        // Handle error responses here
        console.error("Failed to initiate download:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      throw error
    }
  };
}

const client = new Client();
export default client;
