import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { SeverityPill } from "../../components/severity-pill";
import { Package, PackageStatus } from "../../types";
import { Scrollbar } from "../../components/scroll-bar";
import { useNavigate } from "react-router-dom";
import { paths } from "../../components/layout/dashboard/config";
import { formatTimeAgo } from "../../utils";

interface OverviewLatestOrdersProps {
  orders: Package[];
  sx?: object;
}


export const statusMap: { [key: string]: string } = {
  [PackageStatus.PENDING]: "warning",
  [PackageStatus.ACCEPTED]: "info",
  [PackageStatus.PICKED]: "info",
  [PackageStatus.RIDER_CANCELLED]: "error",
  [PackageStatus.CUSTOMER_CANCELLED]: "error",
  [PackageStatus.ADMIN_CANCELLED]: "error",
  [PackageStatus.REACHED_DESTINATION]: "info",
  [PackageStatus.DELIVERY_ATTEMPTED]: "info",
  [PackageStatus.DELIVERED]: "success",
  [PackageStatus.ADMIN_CONFIRMED]: "success",
};
export const paymentStatus: { [key: string]: string } = {
  PENDING: "warning",
  COMPLETED: "success",
  CANCELLED: "error",
  REVERSED: "error",
  PARTIAL: "info",
};
export const OverviewLatestOrders: React.FC<OverviewLatestOrdersProps> = (
  props
) => {
  const { orders = [], sx } = props;
  const navigate = useNavigate();

  return (
    <Card sx={sx}>
      <CardHeader title="Latest Orders" />
      <Scrollbar sx={{ flexGrow: 1 }}>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Order Number</TableCell>
                <TableCell>User</TableCell>
                <TableCell >Date</TableCell>
                <TableCell>Fee</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>No. Riders</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders &&
                orders.map((order, i) => {
                  return (
                    <TableRow hover key={i}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{order.package_number}</TableCell>
                      <TableCell>
                        {order.user?.first_name} {order.user?.last_name}
                      </TableCell>
                      <TableCell>{formatTimeAgo(order.created_at)}</TableCell>
                      <TableCell>{order.cost}</TableCell>

                      <TableCell>
                        <SeverityPill
                          color={
                            paymentStatus[
                            order.payment?.status ?? "PENDING"
                            ] as any
                          }
                        >
                          {order.payment?.status}
                        </SeverityPill>
                      </TableCell>
                      <TableCell>
                        <SeverityPill color={statusMap[order.status] as any}>
                          {order.status}
                        </SeverityPill>
                      </TableCell>
                      <TableCell>{order.broadcast_to.length}</TableCell>
                      <TableCell>
                        <Button
                          variant="text"
                          onClick={() => navigate(paths.orders + order.id)}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          color="inherit"
          endIcon={
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          }
          size="small"
          variant="text"
          onClick={() => navigate(paths.orders)}
        >
          View all
        </Button>
      </CardActions>
    </Card>
  );
};
