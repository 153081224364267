import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@mui/material";
import { useAuthContext } from "../../../context/auth-context";

type AccountPopoverProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  open: boolean;
};

export const AccountPopover: React.FC<AccountPopoverProps> = ({
  anchorEl,
  onClose,
  open,
}) => {
  const navigate = useNavigate();
  const auth = useAuthContext();

  const handleSignOut = useCallback(() => {
    onClose?.();
    auth.logout();
    navigate("/auth/login");
  }, [onClose, auth, navigate]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
    >
      <Box sx={{ py: 1.5, px: 2 }}>
        <Typography variant="overline">Account</Typography>
        <Typography color="text.secondary" variant="body2">
          { auth.user?.first_name} {auth.user?.last_name}
        </Typography>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{ p: "8px", "& > *": { borderRadius: 1 } }}
      >
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </MenuList>
    </Popover>
  );
};
