import { format } from "date-fns";
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Scrollbar } from "../../components/scroll-bar";
import { useUsers, useUpdateCustomer } from "../../hooks/use-users";
import React from "react";

interface CustomersTableProps {
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  page: number;
  rowsPerPage: number;
  handleRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchQuery?: string;
}

export const CustomersTable: React.FC<CustomersTableProps> = (props) => {
  const {
    page,
    rowsPerPage,
    handleRowsPerPageChange,
    searchQuery,
    onPageChange
  } = props;

  const { data: users } = useUsers(page, rowsPerPage, searchQuery);
  const { mutate } = useUpdateCustomer();
  const updateIsAdmin = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    mutate({
      id,
      data: {
        is_admin: e.target.checked,
      },
      page,
      searchQuery,
      rowsPerPage,
    });
  };

  return (
    <Card>
      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Signed Up</TableCell>
                <TableCell>Last Active</TableCell>
                <TableCell padding="checkbox">Admin</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.data.map((user, i) => {
                  const isSelected = false;
                  const createdAt = format(
                    Date.parse(user.created_at),
                    "dd/MM/yyyy"
                  );
                  const lastActive = user.last_active
                    ? format(Date.parse(user.last_active), "dd/MM/yyyy HH:mm")
                    : "None";

                  return (
                    <TableRow hover key={user.id} selected={isSelected}>
                      <TableCell>{i + 1 + Math.max(0, page - 1) * rowsPerPage}</TableCell>
                      <TableCell>
                        <Stack alignItems="center" direction="row" spacing={2}>
                          <Avatar
                            src={
                              user.profile_image ?? ""
                            }
                          >
                            {user.first_name.charAt(0)}
                          </Avatar>
                          <Typography variant="subtitle2">
                            {user.first_name} {user.last_name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.phone_number}</TableCell>
                      <TableCell>{createdAt}</TableCell>
                      <TableCell>{lastActive}</TableCell>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={user.is_admin}
                          onChange={(e) => updateIsAdmin(e, user.id)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              <TableRow>
                <TablePagination
                  // convert zero indexed pagination to rank from 1
                  count={users?.count ?? 0}
                  onPageChange={(_, p) => onPageChange(_, p + 1)}
                  page={page - 1}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};
