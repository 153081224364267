import React from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import "simplebar-react/dist/simplebar.min.css";
import { createTheme } from "./theme";
import { AuthProvider } from "./context/auth-context";
import ErrorPage from "./pages/404";
import Login from "./pages/Login";
import DashboardLayout from "./components/layout/dashboard";
import Home from "./pages";
import Users from "./pages/users";
import Settings from "./pages/settings";
import Account from "./pages/account";
import { paths } from "./components/layout/dashboard/config";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ToastProvider from "./context/toast-context";
import OrdersPage from "./pages/orders";
import OrdersDetailsPage from "./pages/order-details";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Riders from "./pages/riders";
import Feedback from "./pages/feedback";
import Transactions from "./pages/transactions";
import Courier from "./pages/couriers";
import CourierDetail from "./pages/courier-detail";
import RiderEarningHistory from "./pages/rider-earning";
import TransactionDetails from "./pages/transaction-detail";
import RiderDetailsPage from "./pages/rider-details";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthProvider children={<Outlet />} />,
    children: [
      {
        path: paths.login,
        element: <Login />,
      },

      {
        path: "/",
        element: <DashboardLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "/",
            element: <Home />,
          },
          {
            path: paths.users,
            element: <Users />,
          },
          {
            path: paths.transactions,
            element: <Transactions />,
            children: [
            ]
          },
          {
            path: paths.couriers,
            element: <Courier />,
          },
          {
            path: paths.couriers + ":id",
            element: <CourierDetail />,
          },
          {
            path: paths.riders,
            element: <Riders />,
            
          },
          {
            path: paths.riders+":id",
            element: <RiderDetailsPage />,
          },
          {
            path: paths.feedback,
            element: <Feedback />,
          },
          {
            path: paths.orders,
            element: <OrdersPage />,
          },
          {
            path: paths.orders + ":id",
            element: <OrdersDetailsPage />,
          },
          {
            path: paths.settings,
            element: <Settings />,
          },
          {
            path: paths.account,
            element: <Account />,
          },

          {
            path: paths.riderEarning,
            element: <RiderEarningHistory />,
          },
          {
            path: paths.transactions + ":id",
            element: <TransactionDetails />
          }
        ],
      },
    ],
  },
]);

const App: React.FC<React.PropsWithChildren> = () => {
  const theme = createTheme();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
  });
  return (
    <ToastProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RouterProvider router={router} />
        </ThemeProvider>
      </QueryClientProvider>
    </ToastProvider>
  );
};

export default App;
